import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/components/main-layout/main-layout.component';
import { PaymentRoutingComponent } from './shared/components/payment-routing/payment-routing.component';
import { SacGuard } from './shared/guards/sac/sac.guard';

const routes: Routes = [
  {
    path: 'campaign/start_new_campaign.php',
    redirectTo: 'new/crowdfunding/medical'
  },
  {
    path: 'new/crowdfunding',
    children: [
      {
        path: 'non-medical',
        canActivate: [SacGuard],
        loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: 'medical',
        canActivate: [SacGuard],
        loadChildren: () => import('./medical-campaign/medical-campaign.module').then(m => m.MedicalCampaignModule)
      },
      {
        path: '',
        loadChildren: () => import('./sac-signup/sac-signup.module').then(m => m.SacSignupModule)
      }
    ]
  },
  {
    path: 'new/campaign-thank-you',
    loadChildren: () => import('./campaign-thankyou/campaign-thankyou.module').then(m => m.CampaignThankyouModule)
  },
  {
    path: 'new/campaign_created',
    loadChildren: () => import('./non-med-thankyou/non-med-thankyou.module').then(m => m.NonMedThankyouModule)
  },
  {
    path: 'new/campaign/new',
    redirectTo: 'new/crowdfunding/medical'
  },
  {
    path: 'new/payment-route',
    component: PaymentRoutingComponent,
    data: {
      page_name: 'payment-redirect-page',
      category: 'payment',
      lastOrderSkip: true
    }
  },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'cause',
        loadChildren: () => import('./medical-fundraising/medical-fundraising.module').then(m => m.MedicalFundraisingModule)
      },
      {
        path: 'new/login',
        loadComponent: () => import('./pages/auto-redirect/auto-redirect.component').then(c => c.AutoRedirectComponent),
        data: {
          page_name: 'auto_redirect',
          category: 'redirect url',
          lastOrderSkip: true
        }
      },
      {
        path: 'stories',
        loadChildren: () => import('./story/story.module').then(m => m.StoryModule)
      },
      {
        path: 'newstories',
        loadChildren: () => import('./story/story.module').then(m => m.StoryModule)
      },
      {
        path: 'new/kettokart/thankyou',
        loadChildren: () => import('./thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
      },
      {
        path: 'new/thankyou',
        loadChildren: () => import('./thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
      },
      {
        path: 'trustandsafety',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: 'privacypolicy',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: '404',
        loadChildren: () => import('./page-not-found/page-not-found.route').then(r => r.PageNotFound)
      },
      {
        path: 'fundraisers',
        loadComponent: () => import('./pages/all-fundraisers/all-fundraisers.component').then((c) => c.AllFundraisersComponent),
        data: {
          page_name: 'all_fundraiser'
        }
      },
      {
        path: '',
        loadComponent: () => import('./pages/home/home.component').then((c) => c.HomeComponent),
        data: {
          page_name: 'home'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
